<template>
  <widget-card title="Zakres usług">
    <span class="service-name" v-for="service in services" :key="service.id">
      {{ service.name }}
    </span>
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import { inject, computed } from 'vue';

export default {
  name: 'FinancingCard',
  setup() {
    const company = inject('company');
    const services = computed(() => company?.value?.provider?.services ?? []);

    return { company, services };
  },
  components: {
    WidgetCard,
  },
};
</script>

<style lang="sass" scoped>
.service-name
  margin-right: 5px
  background: rgba(213, 211, 211, 0.57)
  padding: 1px 8px
  border-radius: 5px
  font-weight: 600
  text-transform: uppercase
  font-size: 9pt
</style>
