var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Zakres usług"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c('span', {
      key: service.id,
      staticClass: "service-name"
    }, [_vm._v(" " + _vm._s(service.name) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }