<template>
  <widget-card title="Finanse">
    <table>
      <tbody v-if="provider">
        <tr>
          <td>termin płatności:</td>
          <td v-if="provider.payment_days !== null">{{ provider.payment_days }} dni</td>
          <td v-else>-</td>
        </tr>
        <tr>
          <td>limit kredytowy:</td>
          <td v-if="provider.credit_limit !== null">{{ provider.credit_limit }} PLN</td>
          <td v-else>-</td>
        </tr>
        <tr>
          <td>wykorzystany limit kredytowy:</td>
          <td v-if="provider.used_limit !== null">{{ provider.used_limit }} PLN</td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import { computed, inject } from 'vue';

export default {
  name: 'FinancingCard',
  setup() {
    const company = inject('company');
    const provider = computed(() => company.value?.provider);

    return { provider };
  },
  components: {
    WidgetCard,
  },
};
</script>

<style lang="sass" scoped>
table
  margin-top: 20px
  line-height: 1.7

  td:nth-child(1)
    font-weight: 300
  td:nth-child(2)
    font-weight: bold
    padding-left: 20px
</style>
