var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    attrs: {
      "title": "Finanse"
    }
  }, [_c('table', [_vm.provider ? _c('tbody', [_c('tr', [_c('td', [_vm._v("termin płatności:")]), _vm.provider.payment_days !== null ? _c('td', [_vm._v(_vm._s(_vm.provider.payment_days) + " dni")]) : _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_vm._v("limit kredytowy:")]), _vm.provider.credit_limit !== null ? _c('td', [_vm._v(_vm._s(_vm.provider.credit_limit) + " PLN")]) : _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_vm._v("wykorzystany limit kredytowy:")]), _vm.provider.used_limit !== null ? _c('td', [_vm._v(_vm._s(_vm.provider.used_limit) + " PLN")]) : _c('td', [_vm._v("-")])])]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }