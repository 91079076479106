<template>
  <div class="provider-details" :class="{ visible: detailsVisible() }">
    <b-container fluid>
      <b-row>
        <b-col class="card-column" cols="12" md="12" xl="4">
          <company-details-card />
        </b-col>

        <b-col
          class="card-column"
          cols="12"
          lg="12"
          xl="8"
          v-can:index="'crm.company_address_contact'"
          >
          <company-addresses-card />
        </b-col>

        <b-col
          class="card-column"
          cols="6"
          v-can:index="'crm.provider_service'"
          >
          <financing-card />
        </b-col>

        <b-col class="card-column" cols="6">
          <services-card />
        </b-col>

        <b-col class="card-column" cols="6">
          <company-attachments-card
            v-if="company.files"
            :files="company.files"
          />
        </b-col>

        <b-col cols="6">
          <company-notes-card />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { computed, provide, watch } from 'vue';
import {
  FetchCompanyError,
} from '@/libs/toasts/feedback/module/crm';
import useCompanyDetails from '@/hooks/crm/useCompanyDetails';
import showToast from '@/libs/toasts';
import { useRouter } from '@/@core/utils/utils';
import CompanyAttachmentsCard from '@/components/views/crm/company/widgets/CompanyAttachmentCard.vue';
import CompanyDetailsCard from '@/components/views/crm/company/widgets/CompanyDetailsCard.vue';
import CompanyAddressesCard from '@/components/views/crm/company/widgets/CompanyAddressesCard.vue';
import FinancingCard from '@/components/views/crm/providers/widgets/FinancingCard.vue';
import ServicesCard from '@/components/views/crm/providers/widgets/ServicesCard.vue';
import CompanyNotesCard from '@/components/views/crm/company/widgets/CompanyNotesCard.vue';

export default {
  setup() {
    const { route, router } = useRouter();

    const companyId = computed(() => route.value?.params?.id);
    const companyType = computed(() => route.value?.meta?.resource);

    const {
      company, fetchFailed,
    } = useCompanyDetails(companyId.value);

    watch(fetchFailed, () => {
      if (!fetchFailed.value) return;

      showToast(FetchCompanyError);
      router.push({ name: `crm.${companyType.value}` });
    });

    provide('company', company);
    provide('companyId', companyId.value);

    return { company };
  },
  components: {
    CompanyNotesCard,
    CompanyAddressesCard,
    CompanyDetailsCard,
    FinancingCard,
    ServicesCard,
    CompanyAttachmentsCard,
    BContainer,

    BRow,
    BCol,
  },
  methods: {
    detailsVisible() {
      return true;
    },
  },
  created() {
    // FIXME jak bedzie store dodać tu dla dostawcy.
    // this.$store
    //   .dispatch('crm/provider/fetchOne', { employeeId: this.$route.params.id })
    //   .catch(() => {
    //     this.$showFeedback(FetchEmployeeError, {});
    //     this.$router.push({ name: 'cadre' });
    //   });
  },
};
</script>

<style lang="sass" scoped>
.card-column
  align-items: stretch
</style>
